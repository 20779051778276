// transaction.ts
import { ethers } from 'ethers';

export class Transaction {
  constructor(
    public from: string,
    public to: string,
    public value: number,
    public gasFee: number,
    public data?: any
  ) {}

  // Metode untuk menyimpan transaksi ke JSON
  toJSON(): any {
    return {
      // Properti yang diserialisasi
      from: this.from,
      to: this.to,
      value: this.value,
      gasFee: this.gasFee,
      data: this.data
    };
  }

  // Metode untuk memuat transaksi dari JSON
  static fromJSON(data: any): Transaction {
    return new Transaction(
      data.from,
      data.to,
      data.value,
      data.gasFee,
      data.data
    );
  }
}
