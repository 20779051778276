// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // Production
    apiKey: "AIzaSyAtvWRcixgcR1p_WkZJLP-KF57_uVIwk90",
    authDomain: "oragonx-production.firebaseapp.com",
    projectId: "oragonx-production",
    storageBucket: "oragonx-production.appspot.com",
    messagingSenderId: "723544366985",
    appId: "1:723544366985:web:087d2bb0c3ebcb7d874b58",
    measurementId: "${config.measurementId}",
    vapidKey: "BA1B00FSRVFeqPHKKef5yB_Bh_OB7Ed7rbt2tIVxlNjZZdtOMzPa-DtP53yjRM4Kbs35gjCXicOHVarubFE9k8Q"
    
    // APP
    // apiKey: "AIzaSyBfNKh65hWQwzLzgAoUrvpcsf09cnUqtIw",
    // authDomain: "oragonx-app.firebaseapp.com",
    // projectId: "oragonx-app",
    // storageBucket: "oragonx-app.appspot.com",
    // messagingSenderId: "766605654378",
    // appId: "1:766605654378:web:9d592587898b5da764cd13",
    // measurementId: "G-CYT203MQE5"
    
    // Staging
    // apiKey: "AIzaSyDA_nhLRt9466wGY5ywZtcXWdOtbPas_xU",
    // authDomain: "oragonx-staging.firebaseapp.com",
    // projectId: "oragonx-staging",
    // storageBucket: "oragonx-staging.appspot.com",
    // messagingSenderId: "292415733843",
    // appId: "1:292415733843:web:1f06b3e1b5d11fd3bf86a6",
    // measurementId: "${config.measurementId}",
    // vapidKey: "BH7Dmk7pcc69ah-AYrnp5VlzRc3zBmy9VFHtCFJ25_20rpKaasc-avWh6CF85g_QgV3C7xfRPxuFTOsVTj_Bs28"

    // Development
    // apiKey: "AIzaSyBaoejxFFUKF73iJ2KuqsJajbi7VJ6FSxQ",
    // authDomain: "oragonx-development.firebaseapp.com",
    // databaseURL: "https://oragonx-development-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "oragonx-development",
    // storageBucket: "oragonx-development.appspot.com",
    // messagingSenderId: "891862595791",
    // appId: "1:891862595791:web:fff8fa1e870e1521395fba",
    // measurementId: "${config.measurementId}",
    // vapidKey: "BH7Dmk7pcc69ah-AYrnp5VlzRc3zBmy9VFHtCFJ25_20rpKaasc-avWh6CF85g_QgV3C7xfRPxuFTOsVTj_Bs28"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
