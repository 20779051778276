// block.ts
import { SHA256 } from 'crypto-js';
import { SmartContract } from './smart-contract';
import { Transaction } from './transaction';

// Kelas Block
export class Block {
  private smartContracts: SmartContract[]; // Array untuk menyimpan smart contracts

  constructor(
    public index: number,
    public previousHash: string,
    public timestamp: string,
    public transactions: Transaction[],
    public validatorAddress: string,
    public nonce: number = 0,
    public hash: string = '',
  ) {
    // this.hash = this.calculateHashSync();
  }

  // Metode untuk menghitung hash blok
  calculateHash(): string {
    const blockString = JSON.stringify(this);
    return '0x'+SHA256(blockString).toString();
  }

  // Metode untuk menambang blok dengan kesulitan tertentu
  mineBlock(difficulty: number): void {
    const target = Array(difficulty + 1).join('0');
    while (this.hash.substring(0, difficulty) !== target) {
      this.nonce++;
      this.hash = this.calculateHash();
    }
    console.log('Block mined:', this.hash);
  }

  // Metode untuk menghitung hash secara sinkron (jika diperlukan)
  calculateHashSync(): string {
    const blockString = this.index + this.previousHash + this.timestamp + JSON.stringify(this.transactions) + this.nonce + this.validatorAddress;
    return '0x'+SHA256(blockString).toString();
  }

  // Metode untuk menyimpan blok ke JSON
  toJSON(): any {
    return {
      index: this.index,
      previousHash: this.previousHash,
      timestamp: this.timestamp,
      transactions: this.transactions.map(tx => tx.toJSON()), // pastikan transaksi di-serialisasi
      validatorAddress: this.validatorAddress,
      nonce: this.nonce,
      hash: this.calculateHashSync()
    };
  }

  static fromJSON(data: any): Block {    
    return new Block(
      data.index,
      data.previousHash,
      data.timestamp,
      (data.transactions).map((tx: any) => Transaction.fromJSON(tx)),
      data.validatorAddress,
      data.nonce,
      data.hash
    );
  }
}
