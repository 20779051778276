// blockchain.ts
import { Block } from './block';
import { SmartContract } from './smart-contract';
import { Transaction } from './transaction';

interface Account {
  address: string;
  balance: number;
}

interface Validator {
  address: string;
  stakingPower: number;
}

export class Blockchain {
  chain: Block[];
  block: Block;
  pendingTransactions: any[];
  // accounts: Account[];
  // validators: Validator[];
  // difficulty: number;
  // miningRewardPercentage: number;
  // gasFeePerUnit: number;

  constructor(lastBlock) {
    this.chain = [lastBlock];
    this.pendingTransactions = [];
    // this.accounts = [];
    // this.validators = [];
    // this.difficulty = 3;
    // this.miningRewardPercentage = 0.1; // 10% (0.1) dari total amount
    // this.gasFeePerUnit = 1;

    const savedBlockchain = localStorage.getItem('blockchain');
    if (!savedBlockchain) {
      this.createGenesisBlock();
    }
  }

  async minePendingTransactions(from: string, amount: number, data?: string) {
    // Membuat blok baru dengan transaksi yang tertunda
    console.log(this.chain.length);
    const chain = new Block(
      this.chain.length,
      this.getLatestBlock().hash,
      new Date().toISOString(),
      this.pendingTransactions,
      'validatorAddress'
    );
    this.chain.push(chain);

    console.log(this.chain);
    this.chain.forEach((block, index) => {
      console.log(`Block ${index}:`, block instanceof Block); // Should log true for all blocks
    });

    // Serializing the chain to JSON
    const chainJSON = this.chain.map(block => block.toJSON());
  }

  createGenesisBlock(): Block {
    return new Block(0, '', new Date().toISOString(), [], 'genesis-validator');
  }

  createTransaction(transaction: Transaction): void {
    this.pendingTransactions.push(transaction);
  }

  isChainValid(): boolean {
    const chain = this.chain;
    const isValid = chain.every((block, index) => {
      if (index !== 0 && block.previousHash !== chain[index - 1].hash) {
        console.log(`Invalid Block at index ${index}: Previous Hash mismatch`);
        return false;
      }
      // if (!this.isValidPoS(block)) {
      //   console.log(`Invalid PoS at index ${index}`);
      //   return false;
      // }
      console.log(`Block at index ${index} is valid`);
      return true;
    });

    if (isValid) {
      console.log('Blockchain is valid');
    } else {
      console.log('Blockchain is invalid');
    }
    return isValid;
  }

  isValidPoS(block: Block) {
    // Periksa bukti kepemilikan hanya untuk blok selain blok genesis
    // if (block.index === 0) {
    //   return true; // Blok genesis dianggap valid tanpa PoS
    // }
    // const validatorAddress = block.validatorAddress;
    // this.addValidator('validatorAddress', 1000);
    // const stakingPower = this.getStakingPower(validatorAddress);
    // const requiredStakingPower = 100;
    // return stakingPower >= requiredStakingPower;
  }

  getStakingPower(address: string) {
    // const validator = this.validators.find(val => val.address === address);
    // return validator ? validator.stakingPower : 0; // Just for example, you can set the staking power based on validator's properties.
  }

  addAccount(address: string, balance: number): void {
    // this.accounts.push({ address, balance });
  }

  addValidator(address: string, stakingPower: number): void {
    // const existingValidator = this.validators.find(val => val.address === address);
    // if (existingValidator) {
    //   console.log(`Validator ${address} already exists.`);
    // } else {
    //   const newValidator: Validator = { address, stakingPower };
    //   this.validators.push(newValidator);
    //   // console.log(`Validator ${address} added with staking power ${stakingPower}.`);
    // }
  }

  minePosBlock(validatorAddress: string): void {
    // const stakingPower = this.getStakingPower(validatorAddress);
    // const requiredStakingPower = 100;
    // if (stakingPower >= requiredStakingPower) {
    //   this.rewardValidator(validatorAddress, 1);
    // } else {
    //   console.log('Staking power tidak mencukupi untuk menjadi validator.');
    // }
  }

  rewardValidator(validatorAddress: string, amount: number): void {
    // const validator = this.validators.find(val => val.address === validatorAddress);
    // if (validator) {
    //   validator.stakingPower += amount;
    //   console.log(`Reward ${amount} diberikan kepada validator ${validatorAddress}`);
    // } else {
    //   console.log('Alamat validator tidak ditemukan.');
    // }
  }

  resetBlockchain() {
    this.chain = [this.createGenesisBlock()];
    return this.chain;
  }

  getLatestBlock(): Block {
    return this.chain[this.chain.length - 1];
  }

  addTransaction(sender: string, recipient: string, amount: number, gasFee: number = 1): void {
    const transaction = new Transaction(sender, recipient, amount, gasFee);
    this.pendingTransactions.push(transaction);
  }

  addBlock(newBlock: Block): void {
    newBlock.previousHash = this.getLatestBlock().hash;
    newBlock.hash = newBlock.calculateHash();
    this.chain.push(newBlock);
  }

  areTransactionsValid(transactions: any[]): boolean {
    return true;
  }

  generateNextBlock(transactions: Transaction[], validatorAddress: string): Block {
    // const gasFees = transactions.reduce((total, tx) => total + tx.gasFee, 0);

    // const rewardTransaction = new Transaction(null, validatorAddress, gasFees, 0);
    // transactions.push(rewardTransaction);

    const newBlock = new Block(
      this.chain.length,
      this.getLatestBlock().hash,
      new Date().toISOString(),
      transactions,
      validatorAddress
    );

    // newBlock.mineBlock(this.difficulty);
    this.chain.push(newBlock);

    console.log("newBlock", newBlock);
    return newBlock;
  }

  // Metode untuk menyimpan blockchain ke JSON
  toJSON(): any {
    return {
      chain: this.chain.map(block => block.toJSON())
      // pendingTransactions: this.pendingTransactions.map(tx => tx.toJSON())
    };
  }

  // Metode untuk memuat blockchain dari JSON
  static fromJSON(data: any): Blockchain {
    const blockchain = new Blockchain(data);
    blockchain.chain = data.chain.map((blockData: any) => Block.fromJSON(blockData));
    // blockchain.pendingTransactions = data.pendingTransactions.map((txData: any) => Transaction.fromJSON(txData));
    return blockchain;
  }
}